import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { space } from 'folio-common-components';
import { invariant } from 'folio-common-utils';
import { colors } from 'folio-design-tokens';
import * as React from 'react';
import { InfoIcon } from '../../icons';
import { useSelector } from '../../state';
import { removeTrailingDot } from '../../utils';
import { TextButton } from '../buttons';

const GreyBox = styled.div`
  border-radius: var(--border-radius);
  background-color: var(--blue-200);
  ${space([16], 'padding')};
`;

type Props = {
  onEditCustomClicked: () => void;
  onResetClicked: () => void;
  shouldFocusSummary: boolean;
};

export const SelectedCompanyPurposeForm: React.FC<Props> = props => {
  const { onEditCustomClicked, onResetClicked, shouldFocusSummary } = props;
  const purpose = useSelector(e => e.editor.coreInfo.purpose);
  const activityId = useSelector(e => e.editor.coreInfo.activityId);
  const isCustom = !activityId;
  const divRef = React.useRef<HTMLDivElement>(null);

  invariant(purpose, 'This should be impossible');

  React.useLayoutEffect(() => {
    if (shouldFocusSummary) {
      divRef.current?.focus();
    }
  }, [shouldFocusSummary]);

  return (
    <>
      <GreyBox ref={divRef} tabIndex={-1}>
        <p
          css={css`
            margin-top: 0;
          `}
        >
          {removeTrailingDot(purpose)}
        </p>
        <>
          <TextButton
            onClick={() => {
              onResetClicked();
            }}
          >
            {isCustom ? 'Slett' : 'Velg noe annet'}
          </TextButton>
          {isCustom ? (
            <TextButton
              css={space([16], 'margin-left')}
              onClick={() => {
                onEditCustomClicked();
              }}
            >
              Rediger
            </TextButton>
          ) : null}
        </>
      </GreyBox>
      {isCustom && (
        <div
          css={css`
            ${space([32], 'margin-top')};
          `}
        >
          <InfoIcon
            color={colors.blue}
            css={css`
              vertical-align: top;
            `}
          />{' '}
          Beskrivelsen må godkjennes av Brønnøysundregistrene.
        </div>
      )}
    </>
  );
};
