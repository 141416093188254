import { brregTexts } from 'folio-brreg-standardized-texts';
import { invariant } from 'folio-common-utils';
import * as React from 'react';
import { CustomCompanyPurposeForm } from '../../components/CustomCompanyPurposeForm';
import { DefaultCompanyPurposeForm } from '../../components/DefaultCompanyPurposeForm';
import { SelectedCompanyPurposeForm } from '../../components/SelectedCompanyPurposeForm';
import {
  ShortcutClickedForm,
  type ShortcutInfo,
} from '../../components/ShortcutClickedForm';
import { useActions, useSelector } from '../../state';
import { isInvestingOrShelfCompany } from '../../utils';

export interface Purposetext {
  purpose: string;
  kind: 'purposeText';
}

export interface ActivityId {
  activityId: string;
  kind: 'activityId';
}

export type PurposeInfo = {
  purpose: string;
  verbosePurpose: string;
  activityId: string;
  categoryId: string;
  industryCode: string;
};

const createVerbosePurposeText = (purpose: string, id: string): string => {
  if (isInvestingOrShelfCompany(id)) {
    return purpose;
  }
  return `Selge varer og tjenester innen ${purpose} – og annet som naturlig sammenfaller med dette – samt deltakelse i andre selskaper, investeringer og nærliggende virksomhet.`;
};

const getPurposeInfo = (activityId: string) => {
  const text = brregTexts
    .flatMap(category =>
      category.texts.map(
        (text): PurposeInfo => ({
          purpose: text.label,
          activityId: text.id,
          verbosePurpose: createVerbosePurposeText(
            text.verboseModifier,
            text.id,
          ),
          categoryId: category.id,
          industryCode: text.industryCode,
        }),
      ),
    )
    .find(text => text.activityId === activityId);

  invariant(
    text,
    `No standardized text found for purpose with id ${activityId}`,
  );
  return text;
};

export const CompanyPurposeForm: React.FC = () => {
  const purpose = useSelector(e => e.editor.coreInfo.purpose);
  const [shortcutClickedInfo, setShortcutClickedInfo] =
    React.useState<ShortcutInfo | null>(null);
  const [selectedShortcutId, setSelectedShortcutId] = React.useState<
    string | null
  >(null);
  const [editCustom, setEditCustom] = React.useState(false);
  const [shouldFocusInput, setShouldFocusInput] = React.useState(false);
  const [shouldFocusSummary, setShouldFocusSummary] = React.useState(false);
  const { editorActions } = useActions();

  const shortcutClicked = (props: { id: string; label: string }) => {
    const { id, label } = props;

    const { purpose, activityId } = getPurposeInfo(id);

    setShouldFocusInput(false);
    setShortcutClickedInfo({
      standardizedId: activityId,
      standardizedLabel: purpose,
      shortcutLabel: label,
    });
    setSelectedShortcutId(activityId);
  };

  const resetState = () => {
    editorActions.resetCompanyPurpose();
    setShortcutClickedInfo(null);
    setEditCustom(false);
  };

  const setPurpose = (args: ActivityId | Purposetext) => {
    setEditCustom(false);
    setShouldFocusSummary(true);

    if (args.kind === 'activityId') {
      editorActions.setCompanyPurpose(getPurposeInfo(args.activityId));
    } else {
      const { purpose } = args;
      editorActions.setCompanyPurpose({
        purpose,
        verbosePurpose: purpose,
      });
    }
  };

  if (editCustom) {
    return (
      <CustomCompanyPurposeForm
        onResetClicked={() => {
          resetState();
          setShouldFocusInput(true);
        }}
        onPurposeChosen={setPurpose}
      />
    );
  }

  if (purpose) {
    return (
      <SelectedCompanyPurposeForm
        onEditCustomClicked={() => setEditCustom(true)}
        onResetClicked={() => {
          resetState();
          setShouldFocusInput(true);
        }}
        shouldFocusSummary={shouldFocusSummary}
      />
    );
  }

  if (shortcutClickedInfo) {
    return (
      <ShortcutClickedForm
        shortcutInfo={shortcutClickedInfo}
        onResetClicked={resetState}
        onPurposeChosen={setPurpose}
      />
    );
  }

  return (
    <DefaultCompanyPurposeForm
      onCustomClicked={() => setEditCustom(true)}
      onShortcutClicked={shortcutClicked}
      onPurposeChosen={setPurpose}
      selectedShortcutId={selectedShortcutId}
      resetSelectedShortcut={() => setSelectedShortcutId(null)}
      shouldFocusInput={shouldFocusInput}
    />
  );
};
