import styled from '@emotion/styled';
import { PlainButton, space } from 'folio-common-components';
import { formatters } from 'folio-common-utils';
import { isProbablyMobile } from '../../utils';

type Props = {
  label: string;
  id: string;
  onClick: (props: { label: string; id: string }) => void;
  shouldFocus: boolean;
};

const StyledButton = styled(PlainButton)`
  background-color: var(--fiken-purple-extra-light);
  color: var(--black-500);
  box-shadow: none;
  border-radius: 64px !important;
  ${space([8], 'margin-right')};
  ${space([4], 'padding-vertical', 'margin-top', 'margin-bottom')};
  ${space([16], 'padding-horizontal')};
  :hover {
    background-color: var(--fiken-purple-light);
    text-decoration: underline;
  }
`;

export const ShortcutButton: React.FC<Props> = props => {
  const { label, id, onClick, shouldFocus } = props;
  return (
    <StyledButton
      onClick={() => onClick({ id, label })}
      /* eslint-disable-next-line jsx-a11y/no-autofocus */
      autoFocus={shouldFocus && !isProbablyMobile}
    >
      {formatters.capitalize(label)}
    </StyledButton>
  );
};
