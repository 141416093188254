import { css } from '@emotion/react';
import styled from '@emotion/styled';
import * as React from 'react';
import TextareaAutosize, {
  type TextareaAutosizeProps,
} from 'react-textarea-autosize';
import { Label } from '../Label';
import {
  type TextfieldProps,
  textLikeInput,
  textLikeInputError,
  useTextfieldMessage,
} from '../text-field';

const sharedStyle = css`
  ${textLikeInput};
  min-height: 5em;
  display: block;
`;

const StyledTextarea = styled.textarea`
  ${sharedStyle};
  resize: vertical;
`;

const StyledTextareaAutosize = styled(TextareaAutosize)`
  ${sharedStyle};
  resize: none;
`;

export interface Props
  extends Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'onChange'> {
  value: string;
  onChange: (val: string) => void;
}

interface ResizeProps
  extends TextfieldProps,
    Omit<TextareaAutosizeProps, 'value' | 'onChange' | 'ref'> {}

export const Textarea: React.FC<Props> = ({ onChange, ...rest }) => (
  <StyledTextarea {...rest} onChange={event => onChange(event.target.value)} />
);

const ResizableTextareaWithRef: React.ForwardRefRenderFunction<
  HTMLTextAreaElement,
  ResizeProps
> = (props, ref) => {
  const { label, ...rest } = props;

  let id = React.useId();
  if (props.id) {
    id = props.id;
  }

  const { hasError, inputProps, messageEle } = useTextfieldMessage(props);

  return (
    <>
      {label ? <Label htmlFor={id}>{label}</Label> : null}
      <StyledTextareaAutosize
        {...rest}
        ref={ref}
        id={id}
        onChange={event => props.onChange(event.target.value)}
        css={hasError && textLikeInputError}
        {...inputProps}
      />
      {messageEle}
    </>
  );
};

export const ResizableTextarea = React.forwardRef(ResizableTextareaWithRef);
