import { type ActionType, createAction } from 'typesafe-actions';
import type { Person } from '../editor';
import type { FoundingReducerState } from './';

export const actions = {
  updateFounding:
    createAction('UPDATE_FOUNDING')<Partial<FoundingReducerState>>(),
  reset: createAction('RESET_FOUNDING')<undefined>(),
  updateContactInfo: createAction('UPDATE_CONTACT_INFO')<Partial<Person>>(),
};

export type FoundingAction = ActionType<typeof actions>;
