import * as React from 'react';

declare global {
  interface Window {
    dataLayer?: any;
  }
}

export function track(
  _category: string,
  _action: string,
  _label: string,
  _value: number,
  _nonInteraction: boolean,
) {}

export function trackLinkedinEvent(_conversationId: string): void {}

export function useTrack(event: string) {
  const id = new URL(window.location.href).searchParams.get('id');
  React.useEffect(() => {
    window.dataLayer?.push({ event, transaction_id: id, 'skjema-id': id });
  }, [event, id]);
}
