import { css } from '@emotion/react';
import { Label, fonts, space } from 'folio-common-components';
import * as React from 'react';
import type {
  ActivityId,
  Purposetext,
} from '../../routes/step1/CompanyPurposeForm';
import { track } from '../../utils/track';
import { CompanyPurposeSearch } from '../CompanyPurposeSearch';
import { ShortcutButton } from '../ShortcutButton';

type ShortcutsProps = {
  onShortcutClicked: (props: { id: string; label: string }) => void;
  selectedShortcutId: string | null;
};

const Shortcuts: React.FC<ShortcutsProps> = props => {
  const { onShortcutClicked, selectedShortcutId } = props;

  const buttonInfo = [
    { label: 'IT-konsulent', id: '1620' },
    { label: 'holdingselskap', id: '2835' },
    { label: 'restaurant', id: '2180' },
    { label: 'frisør', id: '1710' },
    { label: 'fotograf', id: '2590' },
    { label: 'bedriftsrådgiving', id: '2650' },
    { label: 'snekker', id: '1440' },
  ];

  return (
    <>
      {buttonInfo.map(info => (
        <ShortcutButton
          key={info.id}
          label={info.label}
          id={info.id}
          onClick={onShortcutClicked}
          shouldFocus={selectedShortcutId === info.id}
        />
      ))}
    </>
  );
};

type Props = {
  onCustomClicked: () => void;
  onShortcutClicked: (props: { id: string; label: string }) => void;
  onPurposeChosen: (args: ActivityId | Purposetext) => void;
  selectedShortcutId: string | null;
  resetSelectedShortcut: () => void;
  shouldFocusInput: boolean;
};

export const DefaultCompanyPurposeForm: React.FC<Props> = props => {
  const {
    onCustomClicked,
    onShortcutClicked,
    onPurposeChosen,
    selectedShortcutId,
    resetSelectedShortcut,
    shouldFocusInput,
  } = props;
  const [searchString, setSearchString] = React.useState('');
  const searchBoxId = React.useId();
  return (
    <>
      <Label htmlFor={searchBoxId}>Finn din bransje eller aktivitet</Label>
      <CompanyPurposeSearch
        searchString={searchString}
        setSearchString={setSearchString}
        onCustomClicked={() => {
          track(
            'aktivitetsvelger',
            'mangelfulle resultater',
            searchString,
            0,
            false,
          );
          onCustomClicked();
        }}
        onPurposeChosen={onPurposeChosen}
        inputId={searchBoxId}
        shouldFocusInput={shouldFocusInput}
      />
      {searchString.trim().length === 0 ? (
        <div onBlur={resetSelectedShortcut}>
          <h4
            css={css`
              ${fonts.font200book};
              margin: 0;
              ${space([32], 'margin-top')};
              ${space([16], 'margin-bottom')};
            `}
          >
            Populære:
          </h4>
          <Shortcuts
            onShortcutClicked={onShortcutClicked}
            selectedShortcutId={selectedShortcutId}
          />
        </div>
      ) : null}
    </>
  );
};
