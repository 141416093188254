import type { BrregTexts } from 'folio-brreg-standardized-texts';
import { removeTrailingDot } from '../../utils';

export type SuggestionText = {
  text: string;
  heading: string;
  searchHitRank: number;
  importanceRank: number;
  id: string;
};

enum SearchHits {
  StartOfSynonym,
  StartOfStandardizedText,
  StartOfCategory,
  MiddleOfSynonym,
  MiddleOfStandardizedText,
  MiddleOfCategory,
}

const rankedSearchHits: SearchHits[] = [
  SearchHits.StartOfSynonym,
  SearchHits.StartOfStandardizedText,
  SearchHits.StartOfCategory,
  SearchHits.MiddleOfSynonym,
  SearchHits.MiddleOfStandardizedText,
  SearchHits.MiddleOfCategory,
];

const removeDuplicates = (list: readonly SuggestionText[]) => {
  return list.filter(
    (value, index, self) =>
      index === self.findIndex(t => t.text === value.text),
  );
};

const suggestionMatchesAllSearchStrings = (suggestion, searchStrings) => {
  for (const s of searchStrings) {
    const isInText = suggestion.text.toLowerCase().includes(s.toLowerCase());
    const isInHeading = suggestion.heading
      .toLowerCase()
      .includes(s.toLowerCase());
    if (!isInHeading && !isInText) {
      return false;
    }
  }
  return true;
};

const createSuggestion = (
  text,
  rank: number,
  heading: string,
): SuggestionText => {
  return {
    heading,
    text: removeTrailingDot(text.label),
    searchHitRank: rank,
    importanceRank: text.importanceRank,
    id: text.id,
  };
};

// public for testing
export function createSuggestions(
  value: string,
  brregStandardizedTexts: BrregTexts,
) {
  // We only search for the first string,
  // we filter the results based on the remaining strings further down
  const [searchString, ...remainingSearchStrings] = value
    .trim()
    .toLowerCase()
    .split(' ');
  const suggestions: SuggestionText[] = [];

  rankedSearchHits.forEach((searchHit, rank) => {
    switch (searchHit) {
      case SearchHits.StartOfSynonym:
        brregStandardizedTexts.forEach(category =>
          category.texts.forEach(text => {
            text.synonyms?.forEach(synonym => {
              if (synonym.toLowerCase().startsWith(searchString)) {
                suggestions.push(createSuggestion(text, rank, synonym));
                return;
              }
            });
          }),
        );
        break;
      case SearchHits.StartOfStandardizedText:
        brregStandardizedTexts.forEach(category =>
          category.texts.forEach(text => {
            if (text.label.toLowerCase().startsWith(searchString)) {
              suggestions.push(createSuggestion(text, rank, category.label));
              return;
            }
          }),
        );
        break;
      case SearchHits.StartOfCategory:
        brregStandardizedTexts.forEach(category => {
          if (category.label.toLowerCase().startsWith(searchString)) {
            category.texts.forEach(text =>
              suggestions.push(createSuggestion(text, rank, category.label)),
            );
            return;
          }
        });
        break;
      case SearchHits.MiddleOfSynonym:
        brregStandardizedTexts.forEach(category =>
          category.texts.forEach(text => {
            text.synonyms?.forEach(synonym => {
              if (synonym.toLowerCase().includes(searchString)) {
                suggestions.push(createSuggestion(text, rank, synonym));
                return;
              }
            });
          }),
        );
        break;
      case SearchHits.MiddleOfStandardizedText:
        brregStandardizedTexts.forEach(category =>
          category.texts.forEach(text => {
            if (text.label.toLowerCase().includes(searchString)) {
              suggestions.push(createSuggestion(text, rank, category.label));
              return;
            }
          }),
        );
        break;
      case SearchHits.MiddleOfCategory:
        brregStandardizedTexts.forEach(category => {
          if (category.label.toLowerCase().includes(searchString)) {
            category.texts.forEach(text =>
              suggestions.push(createSuggestion(text, rank, category.label)),
            );
            return;
          }
        });
        break;
    }
  });

  const filteredSuggestions = suggestions.filter(s =>
    suggestionMatchesAllSearchStrings(s, remainingSearchStrings),
  );
  filteredSuggestions.sort((a, b) => a.searchHitRank - b.searchHitRank);
  filteredSuggestions.sort((a, b) => a.importanceRank - b.importanceRank);

  return removeDuplicates(filteredSuggestions);
}
